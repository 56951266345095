import * as React from "react";
import Layout from "../components/Layout";

import Seo from "../components/General/Seo";
import useSiteMetadata from "../components/SiteMetadata";
import LatestFixtures from "../components/fixtures/LatestFixtures";


const NotFoundPage = () => {
  const { meta, social } = useSiteMetadata();
  return (
    <Layout darkMenu={`true`} backgroundColour={`primary`}>
      <Seo
        title={"Fixtures" + meta.sitePageTitleSep + meta.siteTitle}
        slug={meta.siteUrl + "/fixtures/"}
        description={meta.siteDescription}
        image={meta.siteImage}
        twitterTitle={"Fixtures"}
        facebookTitle={"Fixtures"}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />

      <LatestFixtures  />
    </Layout>
  );
};

export default NotFoundPage;
