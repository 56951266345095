import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import FixtureCard from "./fixtureCard";
import ClubBadge from "./../../img/SSHAFC-badge-no-words.svg";

export default function LatestFixture() {
  return (
    <StaticQuery
      query={graphql`
        query UpcomingFixturesQuery {
          allGoogleGamesTrainingFinesSheet(
            filter: { team: { ne: "Training" }, scoreUs: { eq: null } }
            sort: { fields: gameID, order: ASC }
          ) {
            edges {
              node {
                id
                Time: formattedDate
                OpponentName: team
                Conceded: scoreThem
                Goals: scoreUs
                result
                HomeAway: home_Away
                urlPath: gameID
                Location: venue
                type
                OpponentBadge: badge
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="container mt-5 py-5">
          <h2 className="mb-4 text-center">Upcoming Fixtures</h2>
          {data.allGoogleGamesTrainingFinesSheet.edges.map((edge) => (
            <>
            {edge.node.Time}
            {/* {edge.node.data.OpponentBadge?.localFiles[0]?.publicURL} */}
            <FixtureCard
              key={edge.node.id}
              date={edge.node.Time}
              venue={edge.node.Location}
              type={edge.node.type}
              homeOrAway={edge.node.HomeAway}
              opponent={edge.node.OpponentName}
              vsBadge={edge.node.OpponentBadge}
              vsBadgeFull={edge.node.OpponentBadge}
              scoreUs={edge.node.Goals}
              scoreThem={edge.node.Conceded}
              path={edge.node.path}
              ClubBadge={ClubBadge}
              />
              </>
          ))}
          <div className="py-5 text-center">
            <Link
              className="btn mx-2 btn-lg btn-secondary d-block d-lg-inline"
              to="/results/"
            >
              View Results
            </Link>
            <Link
              className="btn mx-2 btn-lg btn-outline-secondary mt-3 mt-lg-0 d-block d-lg-inline"
              to="/contact/"
            >
              Book a Game?
            </Link>
          </div>
        </div>
      )}
    />
  );
}
